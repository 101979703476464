<template>
  <nav class="tw-w-full md:tw-w-auto">
    <ul class="tw-flex tw-gap-7.5 tw-justify-center">
      <li class="heading-h4">
        <button @click="onSubmitClick">{{ $t('menu.footer.items.submit') }}</button>
      </li>

      <li class="heading-h4">
        <nuxt-link-locale to="/">
          {{ $t('menu.footer.items.deFight') }}
        </nuxt-link-locale>
      </li>

      <li class="heading-h4">
        <button @click="onContactUsClick">{{ $t('menu.footer.items.contactUs') }}</button>
      </li>
    </ul>
  </nav>

  <app-contact-us
    v-if="store.customerService.isVisible"
    v-model="store.customerService.isVisible"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { useAppStore } from '~/store/app';

const AppContactUs = defineAsyncComponent(() => import('~/layouts/parts/AppContactUs.vue'));

const store = useAppStore();
const onSubmitClick = (): void => {
  store.toggleSubmitDialog(true);
};

const onContactUsClick = () => {
  store.showCustomerService(true);
};
</script>
